import container from "container";

export default {
  baseURL: process.env.REACT_APP_HTTP_BASE_URL,
  transformRequest: [
    (data, headers) => {
      if (container.get("token"))
        headers.common["Authorization"] = "Basic " + container.get("token");
      delete headers.post["Content-Type"];
      return JSON.stringify(data);
    }
  ]
};
