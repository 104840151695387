import container from "container";
import { actionsType as genericActionsType } from "modules/generic";

export const actionsType = {
  APP_VERIFY_USER: "APP_VERIFY_USER",
  APP_REGISTER_SUCCESS: "APP_REGISTER_SUCCESS",
  APP_REGISTER_FAIL: "APP_REGISTER_FAIL"
};

export const login = ({ username, password, token }) => {
  return async dispatch => {
    dispatch({
      type: genericActionsType.APP_WAIT,
      message: "Validando acesso..."
    });

    try {
      const userService = container.get("UserService");
      const result = await (token
        ? userService.loginByToken(token)
        : userService.login(username, password));

      dispatch({ type: genericActionsType.APP_LOGIN, ...result });

      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "success",
          title: "Login realizado com sucesso!"
        }
      });
    } catch (error) {
      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "error",
          message: error.message
        }
      });
    }

    dispatch({ type: genericActionsType.APP_READY });
  };
};

export const checkAuthenticator = username => {
  return async dispatch => {
    dispatch({
      type: genericActionsType.APP_WAIT,
      message: "Validando acesso..."
    });

    const userService = container.get("UserService");
    const user = (
      await userService.find({
        data: [
          { field: "email", condition: "==", value: username },
          {
            field: "type",
            condition: "in",
            value: ["admin", "seller", "distributor"]
          }
        ]
      })
    ).pop();

    if (user) {
      if (!user.auth || !user.auth.email)
        dispatch({
          type: actionsType.APP_REGISTER_SUCCESS
        });
      else {
        dispatch({
          type: actionsType.APP_REGISTER_FAIL
        });
        dispatch({
          type: genericActionsType.APP_ALERT,
          alert: {
            type: "warning",
            message:
              "Usuário já possui senha cadastrada, tente recuperar senha."
          }
        });
      }
    } else
      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "error",
          message: "Usuário não existe."
        }
      });

    dispatch({ type: genericActionsType.APP_READY });
  };
};

export const createCredentials = (username, password) => {
  return async dispatch => {
    dispatch({
      type: genericActionsType.APP_WAIT,
      message: "Criando acesso..."
    });

    try {
      const userService = container.get("UserService");
      const credentials = await userService.createCredentials(
        username,
        password
      );

      dispatch({ type: genericActionsType.APP_READY });

      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "success",
          title: "Acesso criado com sucesso."
        }
      });

      dispatch(login({ token: credentials }));
      dispatch({
        type: actionsType.APP_REGISTER_FAIL
      });
    } catch (error) {
      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "error",
          message: error.message
        }
      });
      dispatch({ type: genericActionsType.APP_READY });
    }
  };
};

export const sendEmailRecover = email => {
  return async dispatch => {
    dispatch({
      type: genericActionsType.APP_WAIT,
      message: "Enviando e-mail..."
    });

    try {
      const userService = container.get("UserService");

      await userService.recoverPassword(email);

      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "success",
          title: "E-mail enviado com sucesso."
        }
      });
    } catch (error) {
      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "error",
          message: error.message
        }
      });
    }
    dispatch({ type: genericActionsType.APP_READY });
  };
};

export const createCustomer = (customer, password, seller) => {
  return async dispatch => {
    dispatch({
      type: genericActionsType.APP_WAIT,
      message: "Registrado usuário..."
    });

    customer.type = "user";
    customer.seller = seller;

    try {
      const userService = container.get("UserService");
      const user = await userService.save(customer);

      try {
        const credentials = await userService.createCredentials(
          user.email,
          password
        );

        dispatch({ type: genericActionsType.APP_READY });

        dispatch({
          type: genericActionsType.APP_ALERT,
          alert: {
            type: "success",
            title: "Usuário registrado com sucesso."
          }
        });

        dispatch(login({ token: credentials }));
        dispatch({
          type: actionsType.APP_REGISTER_FAIL
        });
      } catch (error) {
        await userService.remove(user);
        throw error;
      }
    } catch (error) {
      dispatch({
        type: genericActionsType.APP_ALERT,
        alert: {
          type: "error",
          message: error.message
        }
      });
      dispatch({ type: genericActionsType.APP_READY });
    }
  };
};
