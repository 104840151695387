// import external modules
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import { reducers as genericReducers } from "modules/generic";
import { reducers as geralReducers } from "modules/geral";

const reducer = combineReducers({
  toastr: toastrReducer,
  ...genericReducers,
  ...geralReducers
});
export default reducer;
