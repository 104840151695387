import { actionsType } from "../action/user";

const initialState = {
  canRegister: false
};

export const register = (state = initialState, { type, message }) => {
  switch (type) {
    case actionsType.APP_REGISTER_SUCCESS:
      return {
        ...state,
        canRegister: true
      };
    case actionsType.APP_REGISTER_FAIL:
      return {
        ...state,
        canRegister: false,
        message
      };
    default:
      return state;
  }
};
