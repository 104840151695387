// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider, connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import Moment from "moment";
import "moment/locale/pt-br";
import momentLocalizer from "react-widgets-moment";
import simpleNumberLocalizer from "react-widgets-simple-number";

// import internal(own) modules
import registerServiceWorker from "registerServiceWorker";
import { store } from "app/store";
import Spinner from "app/components/spinner/spinner";

import "font-awesome/css/font-awesome.min.css";
import "index.scss";

const LazyApp = lazy(() => import("./app/app"));
const LoadingSpinner = connect(state => state.loading)(Spinner);

Moment.locale("pt-br");
momentLocalizer();
simpleNumberLocalizer();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner show={true} />}>
      <LoadingSpinner />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={true}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
      <LazyApp />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
