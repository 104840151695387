import container from "container";
import Service from "../generic/Service";
import Distributor from "./model/Distributor";
import UserService from "./service/UserService";
import * as userReducers from "./reducer";

export * from "./action";

export const reducers = {
  ...userReducers
};

container.add(
  "UserService",
  container =>
    new UserService({ adapter: container.get("DefaultPersistAdapter") })
);
container.add(
  "DistributorService",
  container =>
    new Service({
      model: Distributor,
      adapter: container.get("DefaultPersistAdapter")
    })
);
