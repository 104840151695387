// APEX React Template Config File
//-------------------------------

// You can customise the theme with the help of this file

// Change menu background from here, you can place new image also and use it.
import sidebarBGImage from "./app/assets/img/sidebar-bg/07.jpg";

//Template config options
const templateConfig = {
  sidebar: {
    size: "sidebar-md", // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
    backgroundColor: "grey", // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
    backgroundImage: true, // Options: true, false | Set true to show background image
    backgroundImageURL: sidebarBGImage, // Change image from sidebarBGImage import
  },
  spinner: {
    overlay: {
      backgroundColor: "rgba(3, 29, 87, 0.5)",
    },
  },
};

export default templateConfig;
