import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import { toastr } from "react-redux-toastr";

import reducer from "./reducer";
import { actionsType } from "../modules/generic";
import container from "container";

//Toastr Middleware
const toastrMiddleware = store => next => action => {
  if (action.type === actionsType.APP_ALERT && action.alert) {
    const { type, title, message, ...alert } = action.alert;
    switch (type) {
      case "success":
        toastr.success(title, message, alert);
        break;
      case "error":
        toastr.error(message, alert);
        break;
      case "info":
        toastr.info(message, alert);
        break;
      case "warning":
        toastr.warning(message, alert);
        break;
      default:
        toastr.light(title, message, alert);
    }
  }
  next(action);
};
//

//Authentication Middleware
const authMiddleware = () => next => async action => {
  switch (action.type) {
    case actionsType.APP_LOGIN:
      localStorage.setItem("user", JSON.stringify(action.user));
      localStorage.setItem("token", action.token);
      container.add("user", JSON.parse(localStorage.getItem("user")));
      container.add("token", localStorage.getItem("token"));
      document.location.href = "/";
      break;
    case actionsType.APP_LOGOUT:
      localStorage.clear();
      container.add("user", null);
      container.add("token", null);
      await container
        .get("firebase")
        .auth()
        .signOut();
      document.location.href = "/login";
      break;
    default:
      break;
  }
  next(action);
};
container.add("user", JSON.parse(localStorage.getItem("user")));
container.add("token", localStorage.getItem("token"));
container.add("isAllow", container => (type, element) => {
  const user = container.get("user");
  const isAllow = (user && ["admin", type].includes(user.type)) || false;

  if (!element) return isAllow;

  return isAllow ? element : "";
});
//

const middlewares = [thunk, createDebounce(), toastrMiddleware, authMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
