import UserModel from "../model/User";
import Service from "../../generic/Service";
import container from "container";

export default class UserService extends Service {
  constructor(props) {
    props.model = UserModel;
    super(props);
  }

  createCredentials = async (username, password) => {
    const user = (
      await this.find({
        data: [{ field: "email", condition: "==", value: username }]
      })
    ).pop();

    if (user) {
      const auth = container.get("firebase").auth();
      const credentials = await auth.createUserWithEmailAndPassword(
        username,
        password
      );

      user.set("auth", {
        email: credentials.user.uid
      });
      await this.save(user);

      return user.get("auth").email;
    }
  };

  login = async (username, password) => {
    try {
      const auth = container.get("firebase").auth();
      const userAuth = await auth.signInWithEmailAndPassword(
        username,
        password
      );

      return this.loginByToken(userAuth.user.uid);
    } catch (error) {
      if (["auth/user-not-found", "auth/wrong-password"].includes(error.code))
        throw new Error("Usuário ou senha inválido.");

      throw error;
    }
  };

  loginByToken = async token => {
    const user = (
      await this.find({
        data: [{ field: "auth.email", condition: "==", value: token }]
      })
    ).pop();

    if (!user) throw new Error("Token inválido.");

    return {
      user: user.getData(),
      token: user.auth.email
    };
  };

  recoverPassword = async email => {
    const auth = container.get("firebase").auth();

    await auth.sendPasswordResetEmail(email);
  };
}
