import axis from "axis.js";
import Adapter from "./adapter/persist/Adapter";
import Model from "./Model";

export default class Service {
  #adapter = null;
  #model = null;
  #result = [];
  #total = 0;
  #filter = [];
  #order = {};

  get model() {
    return this.#model;
  }

  constructor(props) {
    if (!(props.adapter instanceof Adapter))
      throw new Error("Invalid Adapter.");
    if (!Model.isPrototypeOf(props.model)) throw new Error("Invalid Model.");
    this.#adapter = props.adapter;
    this.#model = props.model;
  }

  *iterator() {
    for (let i = 0; i < this.#result.length; i++) {
      yield this.#result[i];
    }
  }

  [Symbol.iterator]() {
    return this.iterator();
  }

  getAdapter = () => {
    return this.#adapter;
  };

  add = data => {
    if (axis.isArray(data)) {
      for (var k in data) {
        this.add(data[k]);
      }
    } else {
      this.#total++;
      this.#result.push(
        data instanceof this.#model ? data : new this.#model(data)
      );
    }
  };

  clear = () => {
    this.#result = [];
    this.#total = 0;

    return this;
  };

  filter = data => {
    this.#filter = [...data, ...this.#filter];

    return this;
  };

  sort = (field, direction) => {
    if (field) {
      this.#order = {
        sort: field,
        dir: direction || "ASC"
      };
    } else
      this.#order = {
        sort: "",
        dir: ""
      };
  };

  async get(id) {
    return new this.#model(
      await this.#adapter.findById(this.#model.getPath(), id)
    );
  }

  async find(data) {
    const service = this;
    data = data || { data: [] };
    data.data = [...service.#filter, ...(data.data ? data.data : [])];

    if (!data.sort && this.#order.sort) {
      data.sort = this.#order.sort;
      data.dir = this.#order.dir ? this.#order.dir : "ASC";
    }

    const result = await service.#adapter.find(service.#model.getPath(), data);

    service.clear();
    if (axis.isArray(result)) service.add(result);

    service.#total = service.#adapter.getTotal();

    return service.#result;
  }

  getTotal = () => {
    return this.#total;
  };

  remove = data => {
    var scope = this,
      objs = [];

    return new Promise((resolve, reject) => {
      try {
        if (axis.isArray(data)) {
          for (var k in data) {
            if (data[k] instanceof scope.#model) {
              objs.push(data[k]);
            }
          }
        } else if (data instanceof scope.#model) {
          objs.push(data);
        }

        if (objs.length > 0) {
          this.#adapter
            .remove(scope.#model.getPath(), objs)
            .then(() => resolve(objs))
            .catch(e => reject(e));
        } else {
          throw new Error("Nenhum registro foi passado para remoção");
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  async save(object) {
    const service = this;

    if (!axis.isUndefined(object)) {
      if (!(object instanceof this.#model)) {
        object = new this.#model(object);
      }

      if (!object.getPrimaryKey()) {
        return new this.#model(
          await service.#adapter.insert(service.#model.getPath(), object)
        );
      } else {
        return service.#adapter.update(service.#model.getPath(), object);
      }
    } else if (axis.isNull(object)) {
      const promises = service.#result.map(async object => {
        if (axis.isNull(object.getPrimaryKey())) {
          return new this.#model(
            await service.#adapter.insert(service.#model.getPath(), object)
          );
        } else {
          return service.#adapter.update(service.#model.getPath(), object);
        }
      });

      return Promise.all(promises);
    }
  }
}
