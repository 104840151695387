import axis from "axis.js";

export default class Model {
  _primaryKey = ["id"];
  _protected = {};
  static path = "";

  constructor(props) {
    if (axis.isString(props) || axis.isNumber(props)) this.id = props;
    else if (axis.isObject(props)) {
      this.set(props);
    }
  }

  static getPath() {
    return this.path;
  }

  get = property => {
    return this[property];
  };

  set = (property, value = null) => {
    if (axis.isObject(property)) {
      for (var k in property) {
        var v = property[k];
        this.set(k, v);
      }
    } else {
      this[property] = value;
    }
  };

  getData = () => {
    let data = {};
    Object.keys(this).forEach(value => {
      let newValue =
        typeof this[value] !== "function" &&
        value !== "_primaryKey" &&
        value !== "_protected"
          ? this[value]
          : null;
      if (newValue) {
        newValue = newValue instanceof Model ? newValue.getData() : newValue;
        data[value] = newValue;
      }
    });
    Object.keys(this._protected).forEach(value => {
      if (this[value] === this._protected[value]) {
        data[value] = this._protected[value];
        if (data[value] instanceof Model) data[value] = data[value].getData();
      }
    });
    return data;
  };

  getPrimaryKey = () => {
    var keys = {};
    for (var k in this._primaryKey) {
      let value = this.get(this._primaryKey[k]);
      if (value instanceof Model)
        keys[this._primaryKey[k]] = value.getPrimaryKey();
      else keys[this._primaryKey[k]] = value;
      if (
        axis.isNull(keys[this._primaryKey[k]]) ||
        axis.isUndefined(keys[this._primaryKey[k]])
      )
        return null;
    }
    return keys;
  };
}
