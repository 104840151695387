import Container from "modules/generic/Container";
import config from "config";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

let container = new Container();

container.add("config", config);
container.add(
  "firebase",
  initializeApp({
    apiKey: "AIzaSyC0F5C9ND3aUpGmzVf2blw4nJ66ofRtFy4",
    authDomain: "inventory-management-5c8e6.firebaseapp.com",
    databaseURL: "https://inventory-management-5c8e6.firebaseio.com",
    projectId: "inventory-management-5c8e6",
    storageBucket: "inventory-management-5c8e6.appspot.com",
    messagingSenderId: "388861863233",
    appId: "1:388861863233:web:5cfbaf65151248fe13adc0",
    measurementId: "G-5NKPGSJ78F"
  })
);

export default container;
