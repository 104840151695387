import axis from "axis.js";

export default class Container {
  #services = {};

  add = (service, callback) => {
    this.#services[service] = callback;
  };

  get = service => {
    return this.#services[service]
      ? axis.isFunction(this.#services[service])
        ? this.#services[service](this)
        : this.#services[service]
      : null;
  };
}
