import axis from "axis.js";
import moment from "moment";

export default class Adapter {
  _convertDate = data => {
    var me = this,
      regex = new RegExp(/\d\d\d\d-\d\d-\d\d/),
      aux = {};

    for (var k in data) {
      var v = data[k],
        date = null;
      if (axis.isObject(v) && !axis.isUndefined(v.date)) date = v.date;
      else if (axis.isString(v)) date = v;

      if (!axis.isNull(date) && regex.test(date)) {
        if (date.length >= 26)
          data[k] = moment(date, "YYYY-MM-DD HH:mm:ss.SSSSSS").toDate();
        else if (date.length === 19)
          data[k] = moment(date, "YYYY-MM-DD HH:mm:ss").toDate();
        else if (date.length === 16)
          data[k] = moment(date, "YYYY-MM-DD HH:mm").toDate();
        else if (date.length === 10)
          data[k] = moment(date, "YYYY-MM-DD").toDate();
      } else if (axis.isArray(v)) {
        if (v.length > 0) {
          aux = v.map((v2, k2) => me._convertDate(v2));
        }
        data[k] = aux;
      } else if (axis.isObject(v)) data[k] = me._convertDate(v);
      else data[k] = v;
    }

    return data;
  };

  _convertDateString = data => {
    for (var k in data) {
      var v = data[k];
      if (axis.isDate(v)) {
        data[k] = moment(v).format("YYYY-MM-DD HH:mm:ss");
      } else if (axis.isObject(v)) {
        data[k] = this._convertDateString(v);
      } else data[k] = v;
    }
    return data;
  };

  responseInterceptor = data => {
    var me = this;

    const convertModel = value => {
      return me._convertDateString(value);
    };

    if (axis.isArray(data)) {
      data.forEach((v, i) => {
        data[i] = convertModel(data[i]);
      });
    } else if (axis.isObject(data)) data = convertModel(data);

    return data;
  };
}
