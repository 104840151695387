import { actionsType } from "modules/generic";

const initialState = {
  show: false,
  message: ""
};

export const loading = (state = initialState, { type, message }) => {
  switch (type) {
    case actionsType.APP_WAIT:
      return {
        ...state,
        message: message,
        show: true
      };
    case actionsType.APP_READY:
      return {
        ...state,
        message: "",
        show: false
      };
    default:
      return state;
  }
};
