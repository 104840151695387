import Model from "../../generic/Model";
import Distributor from "./Distributor";

export default class User extends Model {
  static path = "users";

  static userTypes = ["admin", "user", "seller", "distributor"];

  static getDescriptionType = type => {
    switch (type) {
      case "admin":
        return "Administrador";
      case "user":
        return "Cliente";
      case "seller":
        return "Vendedor";
      case "distributor":
        return "Distribuidor";
      default:
        return "";
    }
  };

  set type(value) {
    if (!User.userTypes.includes(value) && ![null, ""].includes(value))
      throw new Error("Invalid user type.");

    this._protected.type = ![null, ""].includes(value) ? value : null;
  }

  get type() {
    return this._protected.type;
  }

  get descriptionType() {
    return User.getDescriptionType(this.type);
  }

  set seller(value) {
    if (value instanceof User) {
      this._protected.seller = value;
    } else {
      this._protected.seller = new User(value);
    }
  }

  get seller() {
    return this._protected.seller;
  }

  set distributor(value) {
    if (value instanceof Distributor) {
      this._protected.distributor = value;
    } else {
      this._protected.distributor = new Distributor(value);
    }
  }

  get distributor() {
    return this._protected.distributor;
  }
}
