import Axios from "axios";
import container from "container";

import FirestoreAdapter from "./adapter/persist/FirestoreAdapter";
import * as appReducers from "./reducer/app";

export const actionsType = {
  APP_WAIT: "APP_WAIT",
  APP_READY: "APP_READY",
  APP_LOGIN: "APP_LOGIN",
  APP_LOGOUT: "APP_LOGOUT",
  APP_ALERT: "APP_ALERT",
  APP_ASKING: "APP_ASKING",
};

export const reducers = {
  ...appReducers,
};

container.add("fetchHttp", (container) =>
  Axios.create(container.get("config").http)
);
container.add("DefaultPersistAdapter", () => new FirestoreAdapter());
