import axis from "axis.js";
import Adapter from "./Adapter";
import container from "container";

export default class FirestoreAdapter extends Adapter {
  #total = 0;
  #firestore = null;

  constructor(props) {
    super(props);
    this.#firestore = container.get("firebase").firestore();
  }

  _convertDate = data => {
    Object.keys(data).forEach(key => {
      if (data[key] && data[key].seconds && data[key].toDate)
        data[key] = data[key].toDate();
    });

    return data;
  };

  responseInterceptor = data => {
    const me = this;

    const convertModel = value => {
      return me._convertDate(value);
    };

    if (typeof data.forEach === "function") {
      const results = [];

      data.forEach(doc =>
        results.push(convertModel({ id: doc.id, ...doc.data() }))
      );

      return results;
    } else if (typeof data.data === "function")
      return convertModel({ id: data.id, ...data.data() });

    return convertModel(data);
  };

  findById = async (resource, id) => {
    const doc = await this.#firestore
      .collection(resource)
      .doc(id)
      .get();

    return this.responseInterceptor(doc);
  };

  find = async (resource, data) => {
    const responseInterceptor = this.responseInterceptor;
    let query = this.#firestore.collection(resource);

    if (!axis.isUndefined(data.limit)) {
      query = query.limit(data.limit);
    }

    if (!axis.isUndefined(data.sort) && !axis.isUndefined(data.dir)) {
      query = query.orderBy(data.sort, data.dir.toLowerCase());
    }

    if (!axis.isUndefined(data.data)) {
      if (data.data.length > 0) {
        data.data.forEach(filter => {
          if (["<", "<=", "==", ">=", ">", "in"].includes(filter.condition))
            query = query.where(filter.field, filter.condition, filter.value);
        });
      }
    }

    return responseInterceptor((await query.get()) || []);
  };

  getTotal = () => this.#total;

  insert = async (resource, object) => {
    const responseInterceptor = this.responseInterceptor;
    const doc = await this.#firestore
      .collection(resource)
      .add(Object.assign(object.getData()));

    return responseInterceptor(await doc.get());
  };

  update = async (resource, object) => {
    const doc = await this.#firestore
      .collection(resource)
      .doc(object.get("id"));

    doc.set(Object.assign(object.getData()));

    return object;
  };

  remove = async (resource, data) => {
    if (axis.isArray(data)) {
      const list = data.map(async obj => await this.remove(resource, obj));
      const results = await Promise.all(list);

      return results;
    }

    const doc = await this.#firestore.collection(resource).doc(data.get("id"));

    await doc.delete();

    return data;
  };
}
