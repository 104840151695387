import React from "react";
import classnames from "classnames";
import { BounceLoader } from "react-spinners";
import templateConfig from "templateConfig";

class SpinnerComponent extends React.Component {
  render() {
    const { show, message } = this.props;

    return (
      <div>
        {show ? (
          <div
            className={classnames("sweet-loading-overlay")}
            style={templateConfig.spinner.overlay}
          >
            <div className="sweet-loading">
              <BounceLoader
                className="clip-loader"
                sizeUnit={"px"}
                size={60}
                color={"#FFF"}
                loading={true}
              />
            </div>
            <div className="text">{message}</div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SpinnerComponent;
